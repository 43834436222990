import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderOrganism from "../../organisms/AppHeaderSections/HeaderOrganism";
import FooterOrganism from "../../organisms/FooterOrganism";
import TopMenuOrganism from "../../organisms/TopMenuOrganism";

const withHeaderAndFooter = (Component) => {
  const NestedComponent = ({ ...props }) => {
    const { pathname = "" } = useLocation();

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, [pathname]);

    return (
      <div>
        <TopMenuOrganism />
        <HeaderOrganism />
        <Component {...props} />
        <FooterOrganism />
      </div>
    );
  };
  return NestedComponent;
};

export default withHeaderAndFooter;
